import React from 'react';
import Image from 'next/image';

import { DateFormat } from '@blockworks/ui/date-time';
import { Link } from '@blockworks/ui/legacy/link';

export type NewsletterPageLayoutProps = {
    id: string;
    time: string;
    list?: string;
    subject: string;
    imageUrl?: string;
    previewText?: string;
    linkTo: string;
};

const NewsletterPageItem = (props: NewsletterPageLayoutProps) => {
    const { id, imageUrl, previewText, linkTo, time, list, subject } = props;

    return (
        <div key={`newsletter-${id}`} className="flex justify-start items-start flex-grow p-6">
            <Link href={linkTo} className="border border-brand hover:border-black">
                {imageUrl ? (
                    <Image src={imageUrl} width={190} height={100} alt="NL" />
                ) : (
                    <Image src="/images/newsletter/placeholder.png" width={160} height={90} alt="NL" />
                )}
            </Link>
            <div className="flex flex-col justify-start items-start flex-grow gap-2 px-5">
                <div className="items-center gap-2 py-1">
                    <p className="flex-grow-0 flex-shrink-0 text-xxs text-left uppercase text-brand">
                        <DateFormat date={time} />
                    </p>
                    <p className="text-xxs text-left text-gray-500">{list}</p>
                </div>
                <div className="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-1">
                    <h3 className="flex-grow w-[125px] text-base font-semibold text-left text-[#110b29]">
                        <Link href={linkTo} className="hover:text-brand">
                            {subject}
                        </Link>
                    </h3>
                    {previewText && <p className="text-gray-400">{previewText}</p>}
                </div>
            </div>
        </div>
    );
};

export default NewsletterPageItem;
